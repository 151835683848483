<template>
	<div>
		<f-default-header
			:title="$t('pages.inventory.title')"
			:subtitle="$t('pages.inventory.view.subtitle')" />
		<f-card>
			<v-row>
				<v-col cols="12">
					<f-card-title>
						{{ $t("pages.inventory.view.dataAnalyzed") }}
					</f-card-title>
				</v-col>
			</v-row>
			<v-row :key="refreshList">
				<v-col cols="12">
					<table id="preview_table">
						<thead>
							<tr>
								<th>{{ $t("labels.date") }}</th>
								<th>Part Number</th>
								<th>{{ $t("labels.qtd") }}</th>
								<th class="currency">{{ $t("labels.value") }}</th>
								<th class="currency">{{ $t("labels.totalValue") }}</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(item, index) in products"
								:key="index"
								:class="{ hasOld: item.hasPreviousValue }">
								<td>{{ item.brDate }}&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{{ item.partNumber }}&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td>{{ item.quantity }}&nbsp;&nbsp;&nbsp;&nbsp;</td>
								<td class="currency">
									{{ displayTableValue(item.value, isLaam, "price") }}
								</td>
								<td class="currency">
									{{ displayTableValue(item.totalValue, isLaam, "price") }}
								</td>
							</tr>
						</tbody>
					</table>
				</v-col>
			</v-row>
			<v-row v-if="totals.length == 1">
				<v-col cols="12" class="totalValue">
					{{ $t("labels.totalValue") }}:
					<span>{{ displayTableValue(totals[0].total, isLaam, "price") }}</span>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12" class="totalValue">
					{{ $t("labels.totalValue") }}
					<div v-for="(item, index) in totals" :key="index">
						{{ item.date }}:
						<span>{{ displayTableValue(item.total, isLaam, "price") }}</span>
					</div>
				</v-col>
			</v-row>
		</f-card>

		<div v-if="hasUpdates">
			<f-card-spacer />
			<f-card>
				{{ $t("pages.inventory.view.itensHasUpdate") }}
				<br />
				<br />
				{{ $t("pages.inventory.view.whatDo") }}
				<br />
				<br />
				<v-radio-group v-model="replaceAction">
					<v-radio value="s" label="Substituir" />
					<v-radio value="i" label="Ignorar" />
				</v-radio-group>
			</f-card>
		</div>

		<f-actions-btn-holder>
			<v-btn color="secondary" elevation="0" large @click="back">
				{{ $t("labels.btnCancel") }}
			</v-btn>
			<v-btn
				color="primary"
				elevation="0"
				large
				:disabled="saveDisable"
				@click="save">
				{{ $t("labels.btnSave") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import numberFomater from "../../helpers/number_formater";
import { displayTableValue } from "../../helpers/table_utils";
import { SystemDate } from "../../helpers/dates";

export default {
	data: () => ({
		refreshList: 0,
		hasUpdates: false,
	}),
	computed: {
		distRegion() {
			return this.$store.state.distributor.view.region === "laam"
				? "laam"
				: "br";
		},
		isLaam() {
			return this.distRegion === "laam";
		},
		replaceAction: {
			get() {
				return this.$store.state.inventory.view.replaceAction;
			},
			set(value) {
				this.$store.commit("inventory/updateReplaceAction", value);
			},
		},
		saveDisable() {
			if (this.hasUpdates && !this.replaceAction) {
				return true;
			}
			return false;
		},
		products() {
			return this.$store.state.inventory.view.products.map((item) => {
				item["totalPrice"] = item.price * item.quantity;
				item["value"] = item.price;
				item["totalValue"] = item.totalPrice;
				if (item.date) {
					try {
						var dt = new SystemDate(item.date);
						item["brDate"] = dt.display();
					} catch (_) {
						item["brDate"] = item.date;
					}
				} else {
					item["brDate"] = this.totals[0].date;
					var dateInventory = this.totals[0].date.split("/");
					var dateObject = new Date(
						+dateInventory[2],
						dateInventory[1] - 1,
						+dateInventory[0]
					);
					item.date = dateObject.toISOString();
				}
				item["hasPreviousValue"] = false;

				return item;
			});
		},
		totals() {
			const viewTotals = this.$store.state.inventory.view.totals;
			var stringDate,
				formatedValue,
				month,
				totalsArr = [];
			for (var date in viewTotals) {
				month = date.slice(0, 2);
				stringDate = `01/${month}/${date.slice(2)}`;
				formatedValue = viewTotals[date];
				// console.log( viewTotals[date] )
				if (viewTotals[date]) {
					formatedValue = viewTotals[date];
				}
				totalsArr.push({
					date: stringDate,
					total: formatedValue,
					ref: date,
				});
			}
			return totalsArr.sort((a, b) => {
				if (a.ref < b.ref) return -1;
				if (a.ref > b.ref) return 1;
				return 0;
			});
		},
		// total() {
		//     var total = this.products.reduce((acc, item) => {
		//         // console.log( acc )
		//         if( item.price ){
		//             return acc + ( item.price * item.quantity )
		//         } else {
		//             return acc
		//         }
		//     }, 0)

		//     this.$store.commit( 'inventory/updateTotal', total )

		//     return numberFomater.money( total, { decimalLength: 2 } )
		// }
	},
	created() {
		// this.checkPreviousData()
	},
	methods: {
		displayTableValue(value, isLaam, column, hasFormat = false) {
			return displayTableValue(value, isLaam, column, hasFormat);
		},
		async checkPreviousData() {
			const result = await this.$store.dispatch("inventory/checkPreviousData");
			var hasOld;

			this.$store.state.inventory.view.replaceList = [];
			this.products.forEach((item) => {
				hasOld = result.body.find((it) => it.id == item.id);
				if (hasOld) {
					item.hasPreviousValue = true;
					item.oldPrice = hasOld.price;
					item.oldValue = numberFomater.money(hasOld.price, {
						decimalLength: 2,
					});
					item.oldTotalValue = numberFomater.money(
						hasOld.price * item.quantity,
						{ decimalLength: 2 }
					);

					this.$store.state.inventory.view.replaceList.push(item.id);
				}
			});
			if (result.body.length) this.hasUpdates = true;
			// console.log( this.products )
			this.refreshList++;
			//hasPreviousValue
		},
		async save() {
			const result = await this.$store.dispatch("inventory/save", false);

			// console.log(result);

			if (
				result.status == false &&
				result.message == "this_date_exist_already"
			) {
				var response = await this.$store.dispatch("dialog/show", {
					title: this.$t("labels.error"),
					content: this.$t("alerts.posPreviewAlreadyExists"),
					accept: this.$t("labels.replace"),
					cancel: this.$t("labels.cancel"),
				});
				if (response) {
					await this.$store.dispatch("inventory/save", true);
				}
			}

			this.$router.push({
				name: "inventory-list",
			});
		},
		back() {
			this.$router.push({
				name: "inventory-upload",
			});
		},
	},
};
</script>

<style lang="scss" scoped>
#preview_table {
	width: 100%;
	border-collapse: collapse;

	th {
		text-align: left;
	}

	th,
	td {
		padding: 2px 5px;
	}

	thead tr,
	tr:nth-child(even) {
		background-color: #f5f5f5;
	}

	.currency {
		text-align: right;
	}
}
.totalValue {
	text-align: right;

	span {
		font-weight: bold;
		padding-left: 10px;
		font-size: 1.2em;
	}
}
.hasOld {
	color: red;
}
</style>
